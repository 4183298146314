const contact = {
    title: 'Obtener ayuda',
    description: 'Para solicitudes de ayuda o comentarios sobre el producto, contáctenos a través de los siguientes métodos.',
    discord: {
        title: 'Únete a la comunidad de Discord',
        description: 'Conéctate con otros desarrolladores para encontrar soluciones',
        button: 'Únete',
    },
    github: {
        title: 'Comunicarse en GitHub',
        description: 'Crea un problema y envíalo en GitHub',
        button: 'Abrir',
    },
    email: {
        title: 'Contacta con soporte por correo electrónico',
        description: 'Envíanos un correo electrónico para obtener más información y ayuda.',
        button: 'Enviar',
    },
    reserve: {
        title: 'Reserve su tiempo con el equipo de Seitrace',
        description: 'Reserve rápidamente una sesión para un chat en vivo',
        button: 'Reservar',
    },
};
export default Object.freeze(contact);
