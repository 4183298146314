const get_started = {
    page_title: 'Comenzar',
    title: 'Algo para explorar que te ayudará a tener éxito',
    subtitle: 'Algunas cosas que puedes hacer para obtener rápidamente el valor de Seitrace',
    develop: {
        title: 'Desarrollar: Dedica 5 minutos para integrar tu aplicación',
        title_cloud: 'Desarrollar: Integra tu aplicación de forma segura en minutos',
        subtitle_cloud: 'O toma 5 minutos para integrar tu aplicación con nuestros SDKs y tutoriales preconstruidos.',
    },
    customize: {
        title: 'Personalizar: Ofrece una excelente experiencia de inicio de sesión',
        preview: {
            title: 'Ver la vista previa en directo de la experiencia de inicio de sesión que acabas de personalizar',
            subtitle: 'Prueba la experiencia de inicio de sesión de Seitrace ahora y ve cómo funciona',
        },
        connector: {
            title: 'Añadir más conectores para admitir más métodos de inicio de sesión sociales',
            subtitle: 'Prueba el inicio de sesión sin contraseña y habilita una experiencia segura y sin fricciones para tus clientes',
        },
        continue_customizing: 'Continúa personalizando',
        try_now: 'Pruébalo ahora',
        add_more: 'Añadir más',
    },
    secure: {
        title: 'Seguro: Protege tus recursos',
    },
    manage: {
        title: 'Gestionar: Define control de acceso para tu producto y usuarios',
        rbac: {
            title: 'Agregar control de acceso basado en roles para proteger tus recursos',
            subtitle: 'Controla tus recursos mediante una autorización de roles escalable para diversos casos de uso',
        },
        create_roles: 'Crear roles',
    },
    view_all: 'Ver todo →',
};
export default Object.freeze(get_started);
